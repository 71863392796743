.tabOpen {
  overflow: hidden;
  max-height: 400px;
  transition: all 0.2s linear;
}
.tabClosed {
  overflow: hidden;
  max-height: 0px;
  transition: all 0.2s linear;
}

.iconOpen {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: all 0.2s ease-in;
}

.iconClosed {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: all 0.2s ease-out;
}

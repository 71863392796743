.progressContainer {
  height: 150px;
  padding: 25px;
  overflow: hidden;
  margin-bottom: 30px;
}

.progressPrices {
  display: flex;
  position: relative;
  margin-bottom: 40px;
  color: white;
  font-size: 12px;
  font-family: "Sfpixelateshaded, sans-serif";
}

.progressNfts {
  display: flex;
  position: relative;
  padding-top: 20px;
  color: white;
  font-size: 12px;
  font-family: "Sfpixelateshaded, sans-serif";
}

.progressText {
  font-family: "Pixeboy z8xgd", sans-serif;
  font-size: 16px;
  display: inline-flex;
  position: absolute;
  -webkit-text-fill-color: none;
}

.actionButton {
  width: 90%;
  min-width: 250px;
  max-width: 300px;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  margin-top: 40px !important;
}

.actionButtonFull {
  width: 500px;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  margin-top: 40px !important;
}

.video {
  height: 500px;
}

.paragraph {
  white-space: pre-wrap;
}

.image {
  max-height: 550px;
  margin-top: 20px;
}

.imageContainer {
  text-align: end;
}

.loadingContainer {
  height: 600px;
}

.loading {
  margin-top: 278px;
  margin-bottom: 277px;
}

.container {
  text-align: center;
  max-width: 1200px;
  margin: auto;
}

.input {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px !important;
  margin-top: 0px !important;
  padding-top: 0px !important;
  width: 100px;
  text-align: center;
}

.buttonContainer > * {
  width: 140px;
}

.chip {
  width: 150px;
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 12px;
  display: inline-block;
  height: 85px;
  margin-right: 7px;
  margin-left: 7px;
  border-style: solid;
  border-width: 3px;
  border-color: #e443de;
  border-radius: 20px;
  background-color: rgba(228, 67, 222, 0.23);
  font-family: Sfpixelate, sans-serif;
  color: #e443de;
  font-weight: 700;
  text-align: center;
  white-space: normal;
}

.properties {
  max-width: 530px;
  margin: auto;
}

.property {
  text-transform: uppercase;
}

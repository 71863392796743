.image {
  max-height: 300px;
  text-align: center;
}

.container {
  text-align: center;
}

.button {
  &:hover {
    background-color: #e443de !important;
  }
}

.loading {
  margin: 100px;
}

.af-view .w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.af-view p {
  margin-bottom: 10px;
}

.af-view .af-class-navbar {
  display: block;
  max-width: 1100px;
  min-height: 11px;
  min-width: 11px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  background-color: #000;
  font-family: Exo, sans-serif;
  font-weight: 500;
  text-align: left;
}

.af-view .af-class-nav-link {
  display: block;
  max-height: 40px;
  margin: 10px 10px -16px 0px;
  padding: 10px;
  background-color: #000;
  font-family: Sfpixelate, sans-serif;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}

.af-view .af-class-nav-link.w--current {
  color: #e443de;
}

.af-view .af-class-div-x {
  display: block;
  margin-top: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  background-color: #000;
}

.af-view .af-class-hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1000px;
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-flex-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-family: Sfpixelateshaded, sans-serif;
  -o-object-fit: fill;
  object-fit: fill;
}

.af-view .af-class-hero-image-mask {
  width: 100%;
  margin-left: 0px;
}

.af-view .af-class-hero-image {
  width: 100%;
  height: 100%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0.5 auto;
  -ms-flex: 0 0.5 auto;
  flex: 0 0.5 auto;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.af-view .af-class-div-block {
  width: 500px;
  margin-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  color: #fff;
}

.af-view .af-class-button {
  width: 500px;
  margin-top: 35px;
  margin-bottom: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #e443de;
  font-family: Sfpixelate, sans-serif;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.af-view .af-class-paragraph {
  margin-bottom: 0px;
  padding-right: 0px;
  padding-left: 30px;
  background-color: #fff;
  font-family: Sfpixelate, sans-serif;
  color: #fff;
  font-weight: 700;
  text-align: justify;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.af-view .af-class-heading {
  margin-bottom: 20px;
  padding-right: 0px;
  background-color: transparent;
  font-family: Sfpixelateshaded, sans-serif;
  color: #e443de;
  font-size: 40px;
  font-weight: 700;
  text-shadow: 0 0 0 #fff;
}

.af-view .af-class-footer {
  padding-top: 60px;
  padding-bottom: 40px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #000;
  text-align: center;
}

.af-view .af-class-footer-flex-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: left;
}

.af-view .af-class-footer-logo-link {
  max-height: 60px;
  min-width: 60px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.af-view .af-class-footer-image {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 0% 50%;
  object-position: 0% 50%;
}

.af-view .af-class-footer-heading {
  margin-top: 0px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.5;
}

.af-view .af-class-footer-link {
  display: block;
  margin-bottom: 10px;
  color: #444;
  text-decoration: none;
}

.af-view .af-class-footer-link:hover {
  text-decoration: underline;
}

.af-view .af-class-text-block {
  font-family: Sfpixelate, sans-serif;
  color: #fff;
  font-weight: 700;
}

.af-view .af-class-image {
  position: relative;
  top: -8px;
  right: -46px;
  display: inline-block;
  width: 250px;
  margin-bottom: 0px;
  margin-left: 100px;
  border-radius: 0px;
  opacity: 1;
  color: transparent;
  text-align: right;
}

.af-view .af-class-paragraph-copy {
  overflow: auto;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  background-color: #fff;
  font-family: Sfpixelate, sans-serif;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-align: justify;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -o-object-fit: contain;
  object-fit: contain;
}

.af-view .af-class-utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  margin-bottom: -105px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000;
}

.af-view .af-class-utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  margin-top: -369px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.af-view .af-class-utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.af-view .af-class-body {
  background-color: #000;
}

.af-view .af-class-icon {
  background-color: #000;
  color: #fff;
}

.af-view .af-class-heading-2 {
  font-family: Sfpixelateshaded, sans-serif;
  color: #e443de;
}

.af-view .af-class-div-block-2 {
  background-color: transparent;
  font-family: Sfpixelate, sans-serif;
  color: #fff;
  font-weight: 700;
}

.af-view .af-class-button-copy {
  margin-top: 10px;
  margin-bottom: 31px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #e443de;
  font-family: Sfpixelate, sans-serif;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}

.af-view .af-class-grid {
  margin-right: 40px;
  margin-left: 40px;
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 0px;
  grid-template-areas: ".";
  -ms-grid-columns: 0.5fr 0px 0.5fr 0px 0.5fr;
  grid-template-columns: 0.5fr 0.5fr 0.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.af-view .af-class-section {
  background-color: #000;
}

.af-view .af-class-image-2 {
  width: 250px;
  margin-right: 0px;
  margin-left: -180px;
}

.af-view .af-class-image-3 {
  width: 250px;
}

.af-view .af-class-image-4 {
  width: 500px;
  margin-right: -1px;
  padding-left: 0px;
}

.af-view .af-class-image-5 {
  width: 250px;
}

.af-view .af-class-section-2 {
  margin-top: 57px;
  background-color: #000;
}

.af-view .af-class-body-2 {
  background-color: #000;
}

.af-view .af-class-hero-copy111 {
  display: block;
  margin-top: 0px;
  padding-top: 50px;
  padding-bottom: 75px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-heading-3 {
  display: block;
  margin-top: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-family: "Pixeboy z8xgd", sans-serif;
  color: #e443de;
  font-size: 35px;
  font-weight: 400;
  text-align: left;
}

.af-view .af-class-flex-container-copy {
  display: block;
  margin-top: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -o-object-fit: fill;
  object-fit: fill;
}

.af-view .af-class-grid-2 {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
  -webkit-align-content: space-between;
  -ms-flex-line-pack: justify;
  align-content: space-between;
  grid-column-gap: 30px;
  grid-row-gap: 20px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.af-view .af-class-image-6 {
  max-width: 90%;
}

.af-view .af-class-hero-2 {
  display: block;
  padding-top: 100px;
  padding-bottom: 100px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-flex-container-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.af-view .af-class-paragraph-2 {
  min-width: 100px;
  text-align: justify;
  white-space: normal;
  -o-object-fit: fill;
  object-fit: fill;
}

.af-view .af-class-heading-4 {
  display: block;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  font-family: "Pixeboy z8xgd", sans-serif;
  color: #e443de;
  font-size: 35px;
  font-weight: 400;
  text-align: left;
}

.af-view .af-class-section-3 {
  position: static;
  width: 1000px;
  margin-right: auto;
  margin-left: auto;
  padding: 0px 0px 49px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  text-align: center;
  -o-object-fit: fill;
  object-fit: fill;
}

.af-view .af-class-text-span {
  color: #e443de;
}

.af-view .af-class-heading-x {
  font-family: "Pixeboy z8xgd", sans-serif;
  color: #fff;
  font-size: 35px;
}

.af-view .af-class-flex-container-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-family: Sfpixelateshaded, sans-serif;
  -o-object-fit: fill;
  object-fit: fill;
}

.af-view .af-class-section-3-copy {
  position: static;
  display: block;
  width: 1000px;
  margin-right: auto;
  margin-left: auto;
  padding: 60px 30px 75px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  text-align: center;
  -o-object-fit: fill;
  object-fit: fill;
}

.af-view .af-class-image-copy {
  position: relative;
  top: -8px;
  right: -46px;
  display: inline-block;
  width: 250px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 100000px;
  opacity: 1;
  text-align: right;
}

.af-view .af-class-heading-4-copy {
  font-family: "Pixeboy z8xgd", sans-serif;
  color: #e443de;
  font-size: 35px;
  font-weight: 400;
  text-align: center;
}

.af-view .af-class-image-7 {
  padding-bottom: 50px;
  border-radius: 1000px;
}

.af-view .af-class-hero-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1000px;
  margin-top: 0px;
  padding-top: 60px;
  padding-bottom: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-section-3-copy {
  position: static;
  display: block;
  width: 1000px;
  margin-right: auto;
  margin-left: auto;
  padding: 0px 30px 75px 0px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  text-align: center;
  -o-object-fit: fill;
  object-fit: fill;
}

.af-view .af-class-paragraph-copy {
  margin-bottom: 0px;
  padding-right: 0px;
  padding-left: 0px;
  background-color: #fff;
  font-family: Sfpixelate, sans-serif;
  color: #fff;
  font-weight: 700;
  text-align: justify;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.af-view .af-class-heading-4-copy {
  font-family: "Pixeboy z8xgd", sans-serif;
  color: #e443de;
  font-size: 35px;
  font-weight: 400;
  text-align: left;
}

.af-view .af-class-heading-4-copy-copy {
  font-family: "Pixeboy z8xgd", sans-serif;
  color: #e443de;
  font-size: 35px;
  font-weight: 400;
  text-align: center;
}

.af-view .af-class-paragraph-copy {
  margin-bottom: 0px;
  padding-right: 0px;
  padding-left: 30px;
  background-color: #fff;
  font-family: Sfpixelate, sans-serif;
  color: #fff;
  font-weight: 700;
  text-align: justify;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.af-view .af-class-paragraph-copy-copy {
  margin-bottom: 0px;
  padding-right: 0px;
  padding-left: 0px;
  background-color: #fff;
  font-family: Sfpixelate, sans-serif;
  color: #fff;
  font-weight: 700;
  text-align: justify;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.af-view .af-class-paragraph-copy-copy {
  margin-bottom: 0px;
  padding-right: 0px;
  padding-left: 0px;
  background-color: #fff;
  font-family: Sfpixelate, sans-serif;
  color: #fff;
  font-weight: 700;
  text-align: justify;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.af-view .af-class-paragraph-copy-copy {
  overflow: auto;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  background-color: #fff;
  font-family: Sfpixelate, sans-serif;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -o-object-fit: contain;
  object-fit: contain;
}

.af-view .af-class-why {
  margin-bottom: 0px;
  padding-right: 30px;
  padding-left: 0px;
  background-color: #fff;
  font-family: Sfpixelate, sans-serif;
  color: #fff;
  font-weight: 700;
  text-align: left;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.af-view .af-class-team {
  margin-bottom: 0px;
  padding-right: 30px;
  padding-left: 0px;
  background-color: #fff;
  font-family: Sfpixelate, sans-serif;
  color: #fff;
  font-weight: 700;
  text-align: left;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.af-view .af-class-over {
  position: static;
  display: block;
  width: 1000px;
  margin-right: auto;
  margin-left: auto;
  padding: 60px 30px 0px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  text-align: center;
  -o-object-fit: fill;
  object-fit: fill;
}

.af-view .af-class-paragraph-copy {
  margin-bottom: 0px;
  padding-right: 0px;
  padding-bottom: 75px;
  padding-left: 0px;
  background-color: #fff;
  font-family: Sfpixelate, sans-serif;
  color: #fff;
  font-weight: 700;
  text-align: center;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.af-view .af-class-accordion-item {
  margin-bottom: 5px;
  padding: 0px 30px 5px 0px;
  border-radius: 10px;
  background-color: #000;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.03);
}

.af-view .af-class-content-wrapper {
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.af-view .af-class-content-wrapper.af-class-slim {
  max-width: 940px;
}

.af-view .af-class-accordion-wrapper {
  margin-top: 50px;
}

.af-view .af-class-icon-2.af-class-accordion-icon {
  font-family: "Material design iconic font", sans-serif;
  color: #fff;
  font-size: 32px;
  line-height: 30px;
  font-style: normal;
  cursor: pointer;
}

.af-view .af-class-accordion-item-trigger {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-family: Sfpixelate, sans-serif;
  font-size: 18px;
}

.af-view .af-class-header-center-box {
  display: block;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.af-view .af-class-header-center-box.af-class-_75 {
  width: 75%;
}

.af-view .af-class-accordion-heading {
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #fff;
  line-height: 30px;
  cursor: pointer;
}

.af-view .af-class-accordion-heading:active {
  color: #e443de;
}

.af-view .af-class-text-center {
  text-align: center;
}

.af-view .af-class-accordion-item-content {
  overflow: hidden;
  margin-top: 10px;
  margin-right: 30px;
}

.af-view .af-class-section-faq {
  padding: 0px 30px 150px;
  background-color: #000;
  color: #fff;
}

.af-view .af-class-italic-text {
  color: #e443de;
  font-size: 25px;
  font-style: normal;
}

.af-view .af-class-italic-text-2 {
  color: #e443de;
  font-size: 25px;
  font-style: normal;
}

.af-view .af-class-italic-text-3 {
  color: #e443de;
  font-size: 25px;
  font-style: normal;
}

.af-view .af-class-italic-text-4 {
  color: #e443de;
  font-size: 25px;
  font-style: normal;
}

.af-view .af-class-italic-text-5 {
  color: #e443de;
  font-size: 25px;
  font-style: normal;
}

.af-view .af-class-italic-text-6 {
  color: #e443de;
  font-size: 25px;
  font-style: normal;
}

.af-view .af-class-faqs {
  font-family: "Pixeboy z8xgd", sans-serif;
  color: #fff;
  font-size: 35px;
  font-weight: 400;
  text-align: left;
}

.af-view .af-class-heading-5 {
  font-family: "Pixeboy z8xgd", sans-serif;
  color: #e443de;
  font-size: 35px;
  font-weight: 400;
}

.af-view .af-class-bold-text {
  display: block;
  font-family: Sfpixelate, sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.af-view .af-class-paragraph-3 {
  font-family: Sfpixelate, sans-serif;
  font-weight: 700;
}

.af-view .af-class-paragraph-4 {
  font-family: Sfpixelate, sans-serif;
  font-weight: 700;
}

.af-view .af-class-paragraph-5 {
  font-family: Sfpixelate, sans-serif;
  font-weight: 700;
}

.af-view .af-class-paragraph-6 {
  font-family: Sfpixelate, sans-serif;
  font-weight: 700;
}

.af-view .af-class-paragraph-7 {
  font-family: Sfpixelate, sans-serif;
  font-weight: 700;
}

.af-view .af-class-paragraph-8 {
  font-family: Sfpixelate, sans-serif;
  font-weight: 700;
}

.af-view .af-class-container {
  display: block;
}

.af-view .af-class-text-span-2 {
  color: #e443de;
  text-decoration: underline;
}

.af-view .af-class-text-span-3 {
  color: #e443de;
  text-decoration: underline;
}

.af-view .af-class-heading-6 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.af-view .af-class-pressed {
  display: block;
  font-family: Sfpixelate, sans-serif;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.af-view .af-class-pressed:hover {
  color: #e443de;
}

.af-view .af-class-pressed:active {
  color: #e443de;
}

.af-view .af-class-pressed:focus {
  color: #e443de;
}

.af-view .af-class-nav-link-copy {
  display: inline-block;
  margin: 10px 10px 10px 0px;
  padding: 10px;
  background-color: #000;
  font-family: Sfpixelate, sans-serif;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}

.af-view .af-class-nav-link-copy.w--current {
  max-height: 40px;
  color: #e443de;
}

.af-view .af-class-nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.af-view .af-class-container-2 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.af-view .af-class-navbar-2 {
  background-color: #000;
}

.af-view .af-class-footerr {
  font-family: Sfpixelate, sans-serif;
  color: #fff;
  font-weight: 700;
}

.af-view .af-class-submit-button {
  background-color: #e443de;
}

.af-view .af-class-paragraph-9 {
  font-family: "IBM Plex Mono", sans-serif;
  color: #fff;
  font-weight: 500;
  text-align: left;
}

.af-view .af-class-image-8 {
  position: fixed;
  max-width: 90%;
}

.af-view .af-class-image-9 {
  max-width: 90%;
}

.af-view .af-class-image-10 {
  max-width: 90%;
}

.af-view .af-class-image-11 {
  max-width: 90%;
}

.af-view .af-class-image-12 {
  max-width: 90%;
}

.af-view .af-class-image-13 {
  max-width: 90%;
}

.af-view .af-class-div-block-3 {
  position: static;
}

.af-view .af-class-section-4 {
  display: block;
}

.af-view .af-class-over-copy {
  position: static;
  display: none;
  overflow: visible;
  width: 1000px;
  margin-right: auto;
  margin-left: auto;
  padding: 60px 30px 0px;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  text-align: center;
  -o-object-fit: fill;
  object-fit: fill;
}

.af-view .af-class-div-block-4 {
  overflow: visible;
}

.af-view .af-class-feature-section {
  padding-top: 100px;
  padding-bottom: 100px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.32);
}

.af-view .af-class-feature-image-mask {
  width: 100%;
  margin-right: 40px;
}

.af-view .af-class-feature-image {
  width: 100%;
  height: 100%;
  margin-bottom: 61px;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}

.af-view .af-class-button-2 {
  display: inline-block;
  width: 100px;
  margin-right: auto;
  margin-left: 100px;
  background-color: #e443de;
  font-family: Sfpixelate, sans-serif;
  font-weight: 700;
  text-align: center;
}

.af-view .af-class-button-3 {
  width: 100px;
  margin-right: 2px;
  margin-left: auto;
  background-color: #e443de;
  font-family: Sfpixelate, sans-serif;
  font-weight: 700;
  text-align: center;
}

.af-view .af-class-heading-7 {
  margin-top: 100px;
  font-family: "Pixeboy z8xgd", sans-serif;
  color: #fff;
  font-size: 42px;
  text-align: center;
}

.af-view .af-class-text-span-4 {
  color: #fff;
}

.af-view .af-class-text-span-5 {
  color: #e443de;
}

.af-view .af-class-button-4 {
  display: inline-block;
  width: 200px;
  height: 100px;
  margin-top: 20px;
  padding-top: 40px;
  border-style: solid;
  border-width: 3px;
  border-color: #e443de;
  border-radius: 20px;
  background-color: rgba(228, 67, 222, 0.23);
  font-family: Sfpixelate, sans-serif;
  color: #e443de;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  white-space: normal;
}

.af-view .af-class-heading-8 {
  font-family: Sfpixelate, sans-serif;
  color: #e443de;
  font-weight: 700;
}

.af-view .af-class-text-span-6 {
  color: #fff;
}

.af-view .af-class-column {
  padding-left: 95px;
}

.af-view .af-class-feature-section-copy {
  padding-top: 100px;
  padding-bottom: 100px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.32);
}

.af-view .af-class-button-4-copy {
  display: inline-block;
  height: 100px;
  margin-top: 20px;
  padding-top: 20px;
  border-style: solid;
  border-width: 3px;
  border-color: #e443de;
  border-radius: 20px;
  background-color: rgba(228, 67, 222, 0.23);
  font-family: Sfpixelate, sans-serif;
  color: #e443de;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  white-space: normal;
}

.af-view .af-class-paragraph-10 {
  color: #fff;
}

@media screen and (max-width: 991px) {
  .af-view .af-class-navbar {
    display: block;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .af-view .af-class-nav-link {
    bottom: 25px;
    width: 100%;
    margin: 0px auto 5px;
    text-align: center;
  }

  .af-view .af-class-nav-link.w--current {
    margin: 0px auto 5px;
  }

  .af-view .af-class-flex-container {
    padding-top: 34px;
  }

  .af-view .af-class-div-block {
    width: auto;
  }

  .af-view .af-class-button {
    width: auto;
  }

  .af-view .af-class-footer {
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-image {
    display: none;
  }

  .af-view .af-class-button-copy {
    margin-top: 0px;
    margin-bottom: 31px;
  }

  .af-view .af-class-flex-container-copy {
    display: block;
  }

  .af-view .af-class-grid-2 {
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-content: space-between;
    -ms-flex-line-pack: justify;
    align-content: space-between;
  }

  .af-view .af-class-heading-4 {
    margin-top: 40px;
    text-align: center;
  }

  .af-view .af-class-section-3 {
    display: block;
    width: auto;
    margin-right: 0px;
    padding-right: 0px;
  }

  .af-view .af-class-section-3-copy {
    display: block;
    width: auto;
    padding-right: 0px;
  }

  .af-view .af-class-heading-4-copy {
    text-align: center;
  }

  .af-view .af-class-paragraph-copy-copy {
    text-align: center;
  }

  .af-view .af-class-why {
    font-family: Sfpixelate, sans-serif;
    text-align: center;
  }

  .af-view .af-class-team {
    padding-right: 0px;
    text-align: center;
  }

  .af-view .af-class-over {
    width: auto;
  }

  .af-view .af-class-paragraph-copy {
    text-align: center;
  }

  .af-view .af-class-heading-5 {
    text-align: center;
  }

  .af-view .af-class-nav-link-copy {
    width: 100%;
    text-align: center;
  }

  .af-view .af-class-nav-link-copy.w--current {
    width: 100%;
    text-align: center;
  }

  .af-view .af-class-nav-menu {
    margin-top: 10px;
    margin-right: 0px;
    background-color: #000;
  }

  .af-view .af-class-container-2 {
    position: relative;
  }

  .af-view .af-class-icon-3 {
    color: #e443de;
  }

  .af-view .af-class-icon-4 {
    color: #e443de;
  }

  .af-view .af-class-section-4 {
    display: none;
  }

  .af-view .af-class-over-copy {
    display: block;
    overflow: visible;
    width: auto;
  }

  .af-view .af-class-menu-button.w--open {
    background-color: #000;
  }
}

@media screen and (max-width: 767px) {
  .af-view .af-class-navbar {
    display: block;
  }

  .af-view .af-class-nav-link {
    bottom: 25px;
    width: 100%;
    margin: 0px auto 5px;
    text-align: center;
    -o-object-fit: fill;
    object-fit: fill;
  }

  .af-view .af-class-nav-link.w--current {
    position: relative;
    display: block;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 5px;
  }

  .af-view .af-class-hero {
    padding: 40px 20px;
  }

  .af-view .af-class-flex-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-hero-image-mask {
    margin-right: 0px;
    margin-left: 0px;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .af-view .af-class-heading {
    text-align: center;
  }

  .af-view .af-class-footer {
    padding: 40px 20px;
  }

  .af-view .af-class-footer-image {
    -o-object-fit: contain;
    object-fit: contain;
  }

  .af-view .af-class-button-copy {
    margin-top: 0px;
  }

  .af-view .af-class-hero-copy111 {
    padding: 40px 20px;
  }

  .af-view .af-class-flex-container-copy {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-hero-2 {
    padding: 40px 20px;
  }

  .af-view .af-class-flex-container-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-heading-4 {
    margin-top: 40px;
    text-align: center;
  }

  .af-view .af-class-section-3 {
    width: auto;
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-flex-container-copy {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-hero-copy {
    padding: 40px 20px;
  }

  .af-view .af-class-section-3-copy {
    padding-right: 30px;
    padding-left: 30px;
  }

  .af-view .af-class-paragraph-copy-copy {
    text-align: center;
  }

  .af-view .af-class-why {
    padding-right: 0px;
    text-align: center;
  }

  .af-view .af-class-team {
    text-align: center;
  }

  .af-view .af-class-paragraph-copy {
    text-align: center;
  }

  .af-view .af-class-accordion-item-content {
    margin-right: 0px;
  }

  .af-view .af-class-nav-link-copy.w--current {
    width: 100%;
    text-align: center;
  }

  .af-view .af-class-nav-menu {
    display: block;
    width: 100%;
    margin-top: 10px;
    margin-right: 0px;
    background-color: #000;
  }

  .af-view .af-class-container-2 {
    position: relative;
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
  }

  .af-view .af-class-paragraph-9 {
    text-align: center;
  }

  .af-view .af-class-menu-button.w--open {
    background-color: #000;
  }

  .af-view .af-class-feature-section {
    padding: 40px 20px;
  }

  .af-view .af-class-feature-image-mask {
    margin-right: 0px;
  }

  .af-view .af-class-feature-image {
    margin-right: auto;
    margin-left: auto;
  }

  .af-view .af-class-feature-section-copy {
    padding: 40px 20px;
  }
}

@media screen and (max-width: 479px) {
  .af-view .af-class-nav-link {
    top: -18px;
    margin-top: 0px;
    margin-bottom: 5px;
    text-align: center;
  }

  .af-view .af-class-nav-link.w--current {
    top: -18px;
    bottom: 28px;
    margin-top: 0px;
    margin-bottom: 5px;
  }

  .af-view .af-class-nav-link.af-class-wallet {
    color: #e443de;
  }

  .af-view .af-class-flex-container {
    display: none;
  }

  .af-view .af-class-div-block {
    display: block;
    width: auto;
    margin-top: 1px;
    margin-right: auto;
    margin-left: auto;
  }

  .af-view .af-class-button {
    display: block;
    width: auto;
  }

  .af-view .af-class-footer {
    padding-right: 20px;
    padding-left: 20px;
    text-align: left;
  }

  .af-view .af-class-footer-flex-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .af-view .af-class-footer-logo-link {
    height: 60px;
  }

  .af-view .af-class-footer-heading {
    margin-top: 20px;
  }

  .af-view .af-class-button-copy {
    margin-top: 0px;
  }

  .af-view .af-class-heading-4 {
    display: none;
    text-align: center;
  }

  .af-view .af-class-heading-4.af-class-mobile {
    display: block;
  }

  .af-view .af-class-section-3 {
    display: inline-block;
    width: auto;
    height: 0px;
    padding-right: 5px;
    padding-left: 5px;
    text-align: center;
  }

  .af-view .af-class-paragraph-copy-copy {
    display: block;
  }

  .af-view .af-class-why {
    text-align: center;
  }

  .af-view .af-class-team {
    margin-right: 20px;
    margin-bottom: 0px;
    margin-left: 20px;
    padding-bottom: 0px;
    padding-left: 0px;
    text-align: center;
  }

  .af-view .af-class-paragraph-copy {
    padding-bottom: 0px;
    text-align: center;
  }

  .af-view .af-class-header-center-box.af-class-_75 {
    width: 90%;
  }

  .af-view .af-class-section-faq {
    padding: 0px 15px 100px;
  }

  .af-view .af-class-nav-link-copy.w--current {
    display: block;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 5px;
    padding-bottom: 10px;
    text-align: center;
  }

  .af-view .af-class-nav-menu {
    margin-top: 10px;
    background-color: #000;
  }

  .af-view .af-class-paragraph-9 {
    position: static;
    text-align: center;
  }

  .af-view .af-class-container-3 {
    display: block;
  }

  .af-view .af-class-container-4 {
    position: static;
    left: auto;
    top: auto;
    right: 0%;
    bottom: 0%;
  }

  .af-view .af-class-div-block-3 {
    display: none;
    overflow: visible;
  }

  .af-view .af-class-over-copy {
    padding-right: 15px;
    padding-left: 15px;
  }

  .af-view .af-class-menu-button.w--open {
    background-color: #000;
  }

  .af-view .af-class-button-4-copy {
    display: inline-block;
    width: 159px;
    height: 85px;
    margin-top: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
  }
}
@font-face {
  font-family: "Material design iconic font";
  src: url("../fonts/Material-Design-Iconic-Font.woff2") format("woff2"),
    url("../fonts/Material-Design-Iconic-Font.eot") format("embedded-opentype"),
    url("../fonts/Material-Design-Iconic-Font.woff") format("woff"),
    url("../fonts/Material-Design-Iconic-Font.ttf") format("truetype"),
    url("../fonts/Material-Design-Iconic-Font.svg") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Pixeboy z8xgd";
  src: url("../fonts/Pixeboy-z8XGD.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Sfpixelate";
  src: url("../fonts/SFPixelate-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Sfpixelate";
  src: url("../fonts/SFPixelate.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Sfpixelate";
  src: url("../fonts/SFPixelate-BoldOblique.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Sfpixelate";
  src: url("../fonts/SFPixelate-Oblique.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Sfpixelateshaded";
  src: url("../fonts/SFPixelateShaded-Oblique.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Sfpixelateshaded";
  src: url("../fonts/SFPixelateShaded.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Sfpixelateshaded";
  src: url("../fonts/SFPixelateShaded-BoldObliqu.ttf") format("truetype"),
    url("../fonts/SFPixelateShaded-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

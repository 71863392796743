.link {
  color: #e443de;
}

.link2 {
  color: white;
  text-decoration: none;
}

.paragraph {
  white-space: pre-wrap;
}

.button {
  height: 40px;
  font-weight: 800;
}

.logo {
  margin-top: 10px;
}
